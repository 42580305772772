import { useEffect, useMemo, useState } from 'react';

import { getDidomiExternalConsentAsync } from '@hubcms/utils-didomi';
import { createUrl, URLLike } from '@hubcms/utils-url';

import { isStringInHostname } from '../isStringInHostname';

export function useExternalConsentUrl(url: string | null): string | null {
  const urlFinal = useMemo(() => (url ? createUrl(url) : null), [url]);
  const [externalUrl, setExternalUrl] = useState(urlFinal);
  const isMhDomain = urlFinal ? isStringInHostname(urlFinal, '.mediahuis.com') : false;

  useEffect(() => {
    if (urlFinal && isMhDomain && !hasMhExternalConsent(urlFinal)) {
      // eslint-disable-next-line promise/catch-or-return, promise/always-return
      appendMhExternalConsent(urlFinal).then(urlWithConsent => {
        setExternalUrl(urlWithConsent);
      });
    } else {
      setExternalUrl(null);
    }
  }, [isMhDomain, urlFinal]);

  return externalUrl?.toString() || null;
}

const externalConsentKey = 'didomiConfig.user.externalConsent.value';
const vendorIds = ['getsitecon-9CqzG7Z6', 'googleana-n6UMhJ7e', 'hotjar-ynF8maUR', 'mediahuis-UhN32Fqq'];

async function appendMhExternalConsent(url: URLLike): Promise<URLLike> {
  try {
    const didomiExternalConsent = await getDidomiExternalConsentAsync(vendorIds);

    url.searchParams.append(externalConsentKey, encodeURIComponent(JSON.stringify(didomiExternalConsent)));

    return url;
  } catch {
    return url;
  }
}

function hasMhExternalConsent(url: URLLike): boolean {
  return url.searchParams.has(externalConsentKey);
}
