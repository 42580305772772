import Cookies, { type CookieAttributes } from 'js-cookie';
import { generateUID } from '@hubcms/utils-tracking';

const GLITR_COOKIE_ID_COOKIE_NAME = '_mhtc_cId';
const GLITR_SESSION_ID_COOKIE_NAME = '_mhtc_sId';

const getSetGlitrId = (glitrIdCookieName: string, glitrCookieOptions?: CookieAttributes) => {
  const cookieValue = Cookies.get(glitrIdCookieName);

  if (cookieValue) {
    return cookieValue;
  }

  const generatedUID = generateUID();
  Cookies.set(glitrIdCookieName, generatedUID, glitrCookieOptions);

  return generatedUID;
};

export const getSetGlitrCookieId = () => {
  /**
   * Cookie expires in 31 days
   */
  return getSetGlitrId(GLITR_COOKIE_ID_COOKIE_NAME, { expires: 31 });
};

export const getSetGlitrSessionId = () => {
  /**
   * Cookie expires in 30 minutes. To express this in days we divide by the amount of minutes in a day (24 hours × 60 minutes).
   */
  return getSetGlitrId(GLITR_SESSION_ID_COOKIE_NAME, { expires: 30 / (24 * 60) });
};
