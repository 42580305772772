import { URLLike, addSearchParams } from '@hubcms/utils-url';

import { createSubscriptionShopUtmParams } from './create-subscription-shop-utm-params';
import { mapSubscriptionShopUtmParams } from './map-subscription-shop-utm-params';

export function getSubscriptionShopUtmUrl(url: URLLike, pageId: string | null | undefined): string {
  const aboshopUtmData = mapSubscriptionShopUtmParams({
    // eslint-disable-next-line camelcase
    utm_artid: pageId,
    // eslint-disable-next-line camelcase
    utm_internal: 'btnheader',
  });
  const aboshopUtmParams = createSubscriptionShopUtmParams(aboshopUtmData);

  url.search = addSearchParams(url.search.toString(), aboshopUtmParams);

  return url.toString();
}
